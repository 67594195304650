import Vue from 'vue'
import { dictionary } from '@/models/finding'
// import moment from 'moment'

// Vue.filter('formatDate', (value, format = 'DD/MM/YY') => {
//   if (!value) { return '' }
//   return moment(value).format(format)
// })


Vue.filter('pluralize', (value, n) => {
  return n-1 ? `${value}s` : value
})

Vue.filter('humanizeFieldName', (value, longname) => {
  if (!value) { return '' }
  return dictionary[value]
    ? dictionary[value][longname?'longname':'shortname']
    : `${value} missing label`
})

Vue.filter('IQMSRNumberOnly', (value) => {
  if (!value) { return '' }
  return (value.match(/(\d+\.)+/)[0]).slice(0, -1)
})

