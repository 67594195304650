<template>
  <div class="textarea-wrapper flex-grow-1" style="position: relative">
    <v-textarea
      v-model="text"
      class="field"
      dense
      flat
      hide-details
      outlined
      :placeholder="placeholder"
      :auto-grow="autoGrow"
      :autofocus="autofocus"
      :disabled="disabled"
      :height="height"
      :no-resize="noResize"
      :readonly="readonly"
      :rows="rows"
      :row-height="20"
    />
    <!-- <div v-if="placeholder && !text" v-html="placeholder" class="placeholder"/> -->
  </div>
</template>

<style>
  .textarea-wrapper .v-input__slot {
    background: white !important;
  }
  .textarea-wrapper .v-input__slot textarea{
  }
  .v-textarea textarea{
    line-height: inherit;
    margin-bottom: 0.5rem !important;
  }
</style>
<style scoped>
  .placeholder{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    padding:9px 12px;
    /*line-height: 1.35;*/
    opacity: 0.5;
  }
</style>

<script>
export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    autoGrow: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: 300
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    noResize: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 3
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    text: {
      get: function() { return this.value },
      set: function(value) {
        const field = this.field
        this.$emit('change', { field, value })
      }
    }
  }
}
</script>
