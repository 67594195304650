<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    _persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-sheet
        rounded
        color="transparent"
        class="d-flex align-center px-1 my-1 nowrap"
        v-bind="attrs"
        v-on="on"
        width="100%"
      >
        <v-icon
          color="primary"
          class="mr-2"
        >
          {{ mdiCalendar }}
        </v-icon>
        {{ display || '&nbsp;' }}
        <v-spacer />
      </v-sheet>
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      :allowed-dates="legalDates"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  props: {
    allowedDates: {
      type: Function
    },
    field: {
      type: String,
      default: ''
    },
    future: {
      type: Boolean,
      default: true
    },
    past: {
      type: Boolean,
      default: true
    },
    today: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      modal: false,
      localValue: this.value,
      mdiCalendar
    }
  },
  computed: {
    display() {
      if (!this.date) return 'Please Choose'
      return new Date(this.date).toIACSformat()
    },
    date: {
      get: function() { return this.localValue },
      set: function(value) {
        this.localValue = value
        const field = this.field
        this.$emit('change', { field, value })
      }
    }
  },
  methods: {
    legalDates (val) {
      return this.allowedDates
        ? this.allowedDates(val)
        : (this.past ? new Date(val) < Date.now() : false) ||
          (this.future ? new Date(val) > Date.now() : false) ||
          (this.today ? new Date(val).toDateString() === new Date().toDateString() : false)
    }
  },
  watch: {
    value: {
      handler (newVal){
        this.localValue = newVal
      }
    }
  }
}
</script>
