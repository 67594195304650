import { fields } from './field-definitions'
import { getFileName } from '@/modules/finding'

// const getFileName = finding => {
//   if(!finding) return ''
//   const acbFinding_Types = {
//     'Non-Conformity': 'NC',
//     'Observation': 'OB',
//     'No Finding': 'NF'
//   }
//   return (
//     finding.acbDate &&
//     finding.acbAudit &&
//     finding.acbFinding_Type
//       ? [[
//           new Date(finding.acbDate).getUTCFullYear(),
//           new Date(finding.acbDate).getUTCMonth() + 1
//         ].join(''),
//         finding.acbAudit.replace(/\s+/g,'_'),
//         acbFinding_Types[finding.acbFinding_Type],
//         finding.acbObs || 'NA'
//         ].join('_')
//       : null
//   )
// }

const state = () => ({
  finding: null
})

const actions = {
  createNew(context) {
    const user = context.rootState.auth.user
    const acUserSelectedCS = context.rootState.acUserSelectedCS
    const assignedTo = user.assignedTo[acUserSelectedCS]
    const defaultCS = assignedTo.value
    const defaultACB = assignedTo.acb.value
    const metadata = {
      created: {
        date: Date.now(),
        user: user._id
      }
    }
    // need to use a local timestamp here.
    const today = [
      new Date().getFullYear(),
      ('0' + (new Date().getMonth()+1)).slice(-2),
      ('0' + new Date().getDate()).slice(-2),
    ].join('-')
    context.state.finding = {
      acbDate: today,
      acbFinding_Type: 'Non-Conformity',
      acbAuditor: user.name,
      acbClass: defaultCS,
      acbACB: defaultACB,
      acbRemote: false,
      metadata
    }
  },
  update({ state }, finding){
    finding.acbFindingFile = getFileName(finding)
    state.finding = finding
  },
  setFinding(context, finding){
    context.state.finding = finding
  },
}

const getters = {
  filename: state => {
    // const finding = state.finding
    return getFileName(state.finding)
  },
  finding: state => {
    // const finding = state.finding
    return state.finding
  },
  canSave: state => {
    return Boolean(
      state.finding &&
      state.finding.acbDate &&
      state.finding.acbAudit &&
      state.finding.acbFinding_Type
    )
  },
  canUpload: (state, getters) => {
    return finding => {

      // create a list of required fields
      const required = fields.filter(field => getters.fieldRequired(field, finding))

      const missing = required.filter(f => {
        // funny exceptions
        switch (f.field) {
          // either sytem or technical is required
          case 'acbSystem':
          case 'acbTechnical':
            return !(finding.acbSystem || finding.acbTechnical)
          // acbRemote is required but it can be false ...
          // acbRemote is boolean ... >.<
          case 'acbRemote':
            return false
          default:
            return !finding[f.field]
        }
      })
      return !missing.length
    }
  },
  fieldDisabled: state => {
    return field => {
      const e = field.enabled
      if (e instanceof Array) {
        return (e.indexOf(state.finding.acbFinding_Type) === -1)
      } else {
        if(field.field === 'acbVCAType') {
          return !(state.finding.acbAuditType === 'VCA' )
        }
        return !e
      }
    }
  },
  fieldRequired: state => {
    return (field, finding) => {

      // this can be called from the finding form or
      // from the canUpload function above, in this case
      // we use the local state.
      // canUpload can be called from line items
      // on the AC Home > Your Audits > Draft Findings tab
      // so will be passing in a LocalFinding.

      finding = finding || state.finding

      let r = field.required

      // some fields are dependent on finding type
      // if r is an array, required will depend on finding type
      if (r instanceof Array) {
        r = r.indexOf(finding.acbFinding_Type) !== -1
        if (!r) return false
      }

      // applu some special rules...
      switch(field.field){
        case 'acbVCAType':
          return finding.acbAuditType === 'VCA'
        case 'acbSystem':
          return !finding.acbTechnical
        case 'acbTechnical':
          return !finding.acbSystem
        default:
          return r
      }
    }
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state
}
