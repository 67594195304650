const getFileName = finding => {
  if(!finding) return ''
  const acbFinding_Types = {
    'Non-Conformity': 'NC',
    'Observation': 'OB',
    'No Finding': 'NF'
  }
  return (
    finding.acbDate &&
    finding.acbAudit &&
    finding.acbFinding_Type
      ? [[
          new Date(finding.acbDate).getUTCFullYear(),
          new Date(finding.acbDate).getUTCMonth() + 1
        ].join(''),
        finding.acbAudit.replace(/\s+/g,'_'),
        acbFinding_Types[finding.acbFinding_Type],
        finding.acbObs || 'NA'
        ].join('_')
      : null
  )
}

export {
  getFileName
}
