<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.enter="cancel"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        flat
        :color="options.color"
      >
        <v-icon v-if="options.icon">{{ options.icon }}</v-icon>
        <v-toolbar-title class="px-3">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="py-4 px-7"
      >
        <div
          class="text-body-1 py-4"
          v-html="message"
        />
      </v-card-text>
      <v-card-actions class="pa-6 pt-2">
        <v-spacer></v-spacer>
        <div
          :class="{
            'd-flex': true,
            'flex-row-reverse': !options.reverseActions,
            'flex-row': options.reverseActions
          }"
        >
          <v-btn
            class="mx-1"
            :color="options.acceptColor"
            :depressed="!options.reverseActions"
            :text="options.reverseActions"
            @click.native="agree"
          >
            {{ options.acceptLabel || 'Yes' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      acceptColor: 'primary darken-1',
      cancelColor: 'primary darken-1',
      width: 320,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    }
  }
}
</script>
