<template>
  <div
    v-show='isActive'
    class="fill-height"
    style="position: relative;"
  >
    <v-overlay
      absolute
      class="mb-12"
      color="white"
      opacity="0.85"
      :value="overlay"
    >
      <v-chip
        color="error"
      >
        <div class="text-caption font-weight-black">OFFLINE</div>
      </v-chip>
    </v-overlay>
    <slot></slot>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    disabledOffline: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Tab'
    },
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    online () { return this.$store.state.socket },
    overlay () { return this.disabledOffline && !this.online }
  },
}
</script>
