<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        dark
        flat
        :color="options.color || 'primary'"
      >
        <v-icon v-if="options.icon">{{ options.icon }}</v-icon>
        <v-toolbar-title class="white--text px-3">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="text-body-1 pt-2 pb-0 px-7"
      >
        <div
          class="pt-4"
          v-html="message"
        />
      </v-card-text>
      <v-card-text
        class="text-body-1 py-2 px-7"
      >
        <slot>
          Default slot
        </slot>
      </v-card-text>
      <v-card-actions class="pa-6 pt-2">
        <v-spacer></v-spacer>
        <div
          :class="{
            'd-flex': true,
            'flex-row-reverse': options.reverseActions,
            'flex-row': !options.reverseActions
          }"
        >
          <v-btn
            class="mx-1"
            :color="options.acceptColor"
            :depressed="options.reverseActions"
            :text="!options.reverseActions"
            :disabled="!canAccept"
            @click.native="agree"
          >
            {{ options.acceptLabel || 'Yes' }}
          </v-btn>
          <v-btn
            class="mx-1"
            :color="options.cancelColor"
            :depressed="!options.reverseActions"
            :text="options.reverseActions"
            @click.native.stop="cancel"
          >
            {{ options.cancelLabel || 'No' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props:{
    canAccept: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      acceptColor: 'primary darken-1',
      cancelColor: 'primary darken-1',
      width: 320,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
