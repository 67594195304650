<template>
  <input
    :autofocus="autofocus"
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="readonly"
    :value="value"
    @input="change"
  />
</template>

<style scoped>
input:disabled{
  border-color: rgba(0, 0, 0, 0.26);
}
input{
  background: white;
  display: block;
  outline: none;
  border:1px solid rgba(0, 0, 0, 0.36);
  border-radius: 3px;
  padding: 5px 12px 7px;
  width:100%;
}
input:focus{
  border-color: rgba(0, 0, 0, 0.56);
  border-width: 1px;
  background: #DEEFF9;
}
</style>

<script>
export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    change (event) {
      const field = this.field
      const value = event.target.value
      this.$emit('change', { field, value })
    }
  }
}
</script>
