<template>
  <v-form
    v-model="valid"
    v-if="!sent"
    ref="resetPassword"
    @submit.prevent="sendPasswordReset(email)"
  >
    <div class="white--text text-center mb-9">
      <span class="">Please enter your email address.</span>
      <br />
      We will send a reset link to your inbox.
    </div>
    <v-text-field
      v-model="email"
      autocomplete="off"
      class="mb-4"
      dense
      flat
      hide-details
      label="Email"
      required
      solo
      type="email"
      :rules="emailRules"
    />
    <v-layout
      class="mt-10 mb-4"
      justify-center
    >
      <v-btn
        class="mx-1"
        color="white"
        type="submit"
        text
        large
        :dark="!valid"
        :ripple="false"
        @click="$emit('change')"
      >
        Cancel
      </v-btn>
      <v-btn
        class="mx-1"
        color="white"
        depressed
        type="submit"
        large
        :loading="waiting"
        :dark="!valid"
        :disabled="!valid"
        :ripple="false"
      >
        send reset link
      </v-btn>
    </v-layout>
  </v-form>
  <div v-else>
    <div class="white--text text-center mb-9">
      <span class="">Reset link was sent.</span>
      <br />
      Please check your email inbox.
    </div>
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'

export default {
  name: 'PWResetForm',
  setup(props, context) {
    const { $store } = context.root
    const { PasswordResetRequest } = context.root.$FeathersVuex.api

    const email = ref('')
    const emailRules = ref([
      v => (!!v || 'Email is required'),
      v => (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v)) || 'Not a valid email'
    ])
    const valid = ref(false)
    const waiting = ref(false)
    const sent = ref(false)

    const sendPasswordReset = (email) => {
      waiting.value = true
      const prr = new PasswordResetRequest({
        email: email.toLowerCase()
      })
      $store.dispatch('password-reset-requests/create', prr).then(() => {
        waiting.value = false
        sent.value = true
      }, () => {
        waiting.value = false
        sent.value = true
      })
    }

    return {
      email,
      emailRules,
      sendPasswordReset,
      sent,
      valid,
      waiting
    }
  }
}
</script>
