<template>
  <div>
  <v-form
    v-model="valid"
    ref="resetPassword"
    @submit.prevent="sendPasswordReset(password)"
  >
    <div class="white--text text-center mb-9">
      <span class="">Please enter a new password.</span>
    </div>
    <v-layout
      column
      class="mb-4"
    >
      <v-text-field
        v-model="password"
        autocomplete="off"
        class="mb-4"
        dense
        flat
        hide-details
        label="New Password"
        required
        solo
        type="password"
        :rules="passwordRules"
      />
      <!-- <div
        class="pl-2"
      > -->
      <!-- </div> -->
      <v-text-field
        v-model="passwordConfirm"
        autocomplete="off"
        class="mb-4"
        dense
        flat
        hide-details
        label="Confirm New Password"
        required
        solo
        type="password"
        :rules="confirmRules"
      />
      <v-chip
      v-if="strength"
        label
        :color="strength.color"
      >
        <div class="text-caption font-weight-black text-align-center">Password Strength: {{ strength.label }}</div>
      </v-chip>
    </v-layout>
    <v-layout
      class="mt-6 mb-4"
      justify-center
    >
      <v-btn
        class="mx-1"
        color="white"
        depressed
        type="submit"
        large
        :loading="waiting"
        :dark="!valid"
        :disabled="!valid"
        :ripple="false"
      >
        Reset Password
      </v-btn>
    </v-layout>
  </v-form>
    <PasswordMeter
      v-show="false"
      :password="password"
      @score="onScore"
    />
  </div>
</template>

<script>
/* eslint-disable */

import { ref } from '@vue/composition-api'

import PasswordMeter from "vue-simple-password-meter";

export default {
  name: 'PWResetForm',
  components: { PasswordMeter },
  setup(props, context) {
    const { $route, $store } = context.root
    const { emit } = context
    // const { PasswordResetRequest } = context.root.$FeathersVuex.api

    const password = ref('')
    const passwordConfirm = ref('')
    const passwordRules = [
      v => !!v || 'Password is required',
      v => !!v && v.length > 3
    ]
    const confirmRules = [
      v => !!v || 'Password must match',
      v => (v === password.value) || 'Password must match',
    ]
    const valid = ref(false)
    const waiting = ref(false)
    const sent = ref(false)

    const strengths = [
      {
        color: 'red lighten-2',
        label: 'VERY WEAK'
      },
      {
        color: 'orange darken-1',
        label: 'WEAK'
      },
      {
        color: 'yellow darken-1',
        label: 'GOOD'
      },
      {
        color: 'lime',
        label: 'STRONG'
      },
      {
        color: 'light-green accent-4',
        label: 'VERY STRONG'
      }
    ]
    const strength = ref(null)
    const onScore = ({ score }) => {
      strength.value = strengths[score] // from 0 to 4
    }

    const sendPasswordReset = async (password) => {
      const uuid = $route.params.uuid
      // const prr = await $store.dispatch('password-reset-requests/find', {
      //   query: {
      //     uuid: $route.params.uuid,
      //   },
      // }).catch(err => {
      //   emit('error', err)
      // })
      // if (!prr[0]){
      //   return emit('error', 'Error: Reset token does not exist')
      // } else {
      const patch = { password, uuid }

      await $store.dispatch(
        'password-reset-requests/patch',
        [ null, patch ]
      )
      .then(() => {})
      .catch(err => {
        if (err.message === 'ok') {
          emit('done')
        } else {
          emit('error', err)
        }
      })
      // }
    }

    return {
      password,
      passwordConfirm,
      passwordRules,
      confirmRules,
      onScore,
      sendPasswordReset,
      sent,
      strength,
      strengths,
      valid,
      waiting
    }
  }
}
</script>
