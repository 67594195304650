<template>
  <v-app>
    <v-main>
      <v-container
        fill-height
        class="pb-16"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col class="px-4">
            <div class="text-h5 pb-2">
              {{ filename }}
            </div>
            <v-divider />
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="mt-0 mb-16"
        >
          <v-col>
            <Finding
                idkey="dingle-view"
                :finding="finding"
              >
            </Finding>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import Finding from '@/components/shared/findings/single-view'

export default {
  components: {
    Finding
  },
  setup(props, context) {
    const { $route } = context.root
    const { Finding } = context.root.$FeathersVuex.api

    const filename = $route.params.filename

    const escapeRegExp = string => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    const params = computed(() => {
      return {
        query: {
           acbFindingFile: { $regex : escapeRegExp(filename), $options: 'i' }
        }
      }
    })

    const data = useFind({
      model: Finding,
      params
    })

    const finding = computed(() => {
      return data.items.value[0]
    })

    return {
      filename,
      finding
    }
  }
}
</script>
