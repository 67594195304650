<template>
  <v-checkbox
    v-model="on"
    class="ma-0 mb-1 ml-1"
    hide-details
    inset
    :indeterminate="indeterminate"
    :disabled="disabled"
    :label="label"
    :readonly="readonly"
    :ripple="false"
    @click.stop="function(){}"
  />
</template>

<style>
</style>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: ''
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    on: {
      get: function() { return this.value },
      set: function(value) {
        const field = this.field
        this.$emit('change', { field, value })
      }
    }
  }
}
</script>
