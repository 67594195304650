<template>
  <Ask ref="ask"
    :canAccept="canAccept"
  >
    <div class="mt-5 mb-3">
      <TextArea
        height="360"
        v-if="field.type=='textarea'"
        placeholder="New Value"
        :auto-grow="false"
        :no-resize="true"
        :value="newValue"
        @change="change"
      ></TextArea>
      <FormAutoComplete
        v-if="field.type=='autocomplete'"
        :field="field.field"
        :items="itemsFrom(field.field)"
        :required="Boolean(field.required)"
        :value="newValue"
        @change="change"
      />
      <FormCheckBox
        v-if="field.type=='checkbox'"
        :field="field.field"
        :required="Boolean(field.required)"
        :value="newValue"
        @change="change"
      />
      <FormDatePicker
        v-if="field.type=='date'"
        :field="field.field"
        :value="newValue"
        @change="change"
      />
      <FormInputText
        v-if="field.type=='input'"
        :field="field.field"
        :placeholder="field.placeholder"
        :readonly="field.readonly"
        :required="Boolean(field.required)"
        :value="newValue"
        @change="change"
      />
      <FormRadioGroup
        v-if="field.type=='radio-group'"
        :column="false"
        :field="field.field"
        :items="itemsFrom(field.field)"
        :readonly="field.readonly"
        :value="newValue"
        @change="change"
      />
      <FormSelect
        v-if="field.type=='select'"
        :field="field.field"
        :items="itemsFrom(field.field)"
        :required="Boolean(field.required)"
        :label="newValue"
        :value="newValue"
        @change="change"
      />
      <FormSwitcher
        v-if="field.type=='switch'"
        :field="field.field"
        :required="Boolean(field.required)"
        :value="newValue"
        @change="change"
      />
    </div>
    <!-- {{field}} -->
  </Ask>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
// import { mapGetters } from 'vuex'
import { mdiPencil } from "@mdi/js"

import { dictionary } from '@/models/finding'
import { fields } from '@/store/modules/draft-finding/field-definitions'

import TextArea from '@/components/UI/forms/textarea'
import FormAutoComplete from '@/components/UI/forms/auto-complete'
import FormCheckBox from '@/components/UI/forms/checkbox'
import FormDatePicker from '@/components/UI/date-picker'
import FormInputText from '@/components/UI/forms/input-text'
import FormRadioGroup from '@/components/UI/forms/radio-group'
import FormSelect from '@/components/UI/forms/select'
import FormSwitcher from '@/components/UI/forms/switch'

export default {
  props: {},
  components: {
    TextArea,
    FormAutoComplete,
    FormCheckBox,
    FormDatePicker,
    FormInputText,
    FormRadioGroup,
    FormSelect,
    FormSwitcher
  },
  data () {
    return {
      initialValue: '',
      newValue: '',
      field: {}
    }
  },
  computed: {
    canAccept () {
      return this.newValue !== this.initialValue
    }
  },
  methods: {
    change (change) {
      this.newValue = change.value
    },
    async open (field, value) {
      // console.log(field, value, typeof value)

      switch (field.field) {
        case 'acbFinding_Type':
          this.field = { ...field, type: 'select', required: true }
          break
        case 'acbRemote':
          this.field = { ...field, type: 'select', required: true }
          break
        case 'acbProposedCA':
          this.field = { ...field, type: 'textarea', width: 800 }
          break
        case 'acbProposedCompDate':
          this.field = { ...field, type: 'date' }
          break
        case 'acbCA':
          this.field = { ...field, type: 'textarea', width: 800 }
          break
        case 'acbCompDate':
          this.field = { ...field, type: 'date' }
          break
        case 'acbCloseDate':
          this.field = { ...field, type: 'date' }
          break
        case 'acbNotes':
          this.field = { ...field, type: 'textarea' }
          break
        case 'ocNotes':
          this.field = { ...field, type: 'textarea' }
          break
        default:
          this.field = fields.find(f => f.field === field.field)
          break;
      }

      // console.log(this.field)

      switch (field.field) {
        case 'acbRemote':
          this.newValue = value ? 'Yes' : 'No'
          break
        case 'acbDate':
        case 'acbProposedCompDate':
        case 'acbCompDate':
        case 'acbCloseDate':
          this.newValue = value
                          ? value.toIACSformat().split('/').reverse().join('-')
                          : null
          break
        default:
          this.newValue = value
          break
      }

      this.initialValue = this.newValue

      // const user = this.$store.state.auth.user
      const result = await this.$refs.ask.open(
        `${dictionary[field.field].longname}`,
        '',
        {
          acceptColor: 'error',
          acceptLabel: 'Commit Change',
          cancelLabel: 'Cancel',
          color: "error",
          icon: mdiPencil,
          reverseActions: true,
          width: this.field.type === 'textarea' ? 800 : 460
        }
      )
      let ret = this.newValue

      switch(field.field){
        case 'acbRemote':
          ret = ret === 'Yes'
          break
      }
      return {
        result,
        newValue: ret
      }
    }
  },
  watch: {
    selected () {
      this.newValue = ''
    }
  },
  setup (props, context) {
    // const { $store } = context.root
    const { ACB, Auditor, ClassSociety, FlagState, ValueList } = context.root.$FeathersVuex.api

    const { items: acbs  } = useFind({
      model: ACB,
      params: {query: {$limit:500}}
    })

    const { items: auditors  } = useFind({
      model: Auditor,
      params: {query: {$limit:500}}
    })

    const { items: classSocieties  } = useFind({
      model: ClassSociety,
      params: {query: {$limit:500}}
    })

    const { items: flagStates  } = useFind({
      model: FlagState,
      params: {query: {$limit:500}}
    })

    const { items: valueLists  } = useFind({
      model: ValueList,
      params: {query: {}}
    })

    // getters

    const itemsFrom = computed(() => {
      return field => {
        if (field=="acbRemote") {
          return ['Yes', 'No']
        }
        if (field=="acbACB") {
          return acbs.value.map(acb => acb.value)
        }
        if (field=="acbAuditor") {
          return auditors.value.map(ac => ac.value)
        }
        if (field=="acbClass") {
          return classSocieties.value.map(cs => cs.value)
        }
        if (field=="acbFlag") {
          return flagStates.value.map(fs => fs.name)
        }
        const item = valueLists.value.find(l => l.field === field)
        if(!item) return
        if (field=="acbIQMSR") {
          return item.values.map(v => v.value)
        }
        return item.values
      }
    })

    return {
      auditors,
      // change,
      fields,
      itemsFrom
    }
  }
}
</script>
