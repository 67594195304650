<template>
  <th
    nowrap
    :class="{
      'px-0': col.compact,
      'px-2': !col.compact,
      'primary--text': true,
      'h-double-rule': true
    }"
    _style="background-color:#eee; border-right:1px solid #ccc"
    @click="showMenu = true"
  >
    <v-menu
      v-model="showMenu"
      offset-y
      offset-overflow
      content-class="elevation-12"
      max-height="65vh"
      tile
      nudge-left="9"
      nudge-bottom="3"
    >
      <template v-slot:activator="{ attrs }">
        <div
          v-bind="attrs"
          class="d-flex justify-space-between"
        >
          <div class="d-flex align-center">
            <span>{{ col.label }}</span>
            <v-icon
              v-if="lsort===null"
              small
              style="visibility:hidden"
              class="pl-1"
            >
              {{ mdiMinus }}
            </v-icon>

            <v-icon
              v-if="lsort==='-1'"
              small
              class="pl-1"
            >
              {{ mdiArrowDown }}
            </v-icon>

            <v-icon
              v-if="lsort==='1'"
              small
              class="pl-1"
            >
              {{ mdiArrowUp }}
            </v-icon>

            <v-icon
              v-if="lselected!==undefined"
              small
            >
              {{ mdiFilterOutline }}
            </v-icon>

            <v-icon
              v-if="lselected===undefined"
              small
              style="visibility:hidden"
            >
              {{ mdiFilterOutline }}
            </v-icon>
          </div>
          <v-icon class="ml-n2">
            {{ mdiMenuDown }}
          </v-icon>
        </div>
      </template>

      <v-list
        dense
        min-width="240"
        outlined
      >

        <v-list-item
          v-if="lsort"
          key="CLEAR_SORT"
          @click="lsort=undefined"
        >
          <v-list-item-icon>
            <v-icon color="error">
              {{ mdiClose }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Clear Sort
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-2" v-if="lsort" />


        <v-list-item-group
          v-model="lsort"
        >
          <v-list-item
            value="1"
          >
            <v-list-item-icon>
              <v-icon>
                {{ mdiSortAlphabeticalAscending }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{
                col.sort && col.sort.asc
                ? col.sort.asc
                : 'A on Top'
              }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            value="-1"
          >
            <v-list-item-icon>
              <v-icon class="ma-0">
                {{ mdiSortAlphabeticalDescending }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{
                col.sort && col.sort.desc
                ? col.sort.desc
                : 'Z on Top'
              }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-divider class="my-2" v-if="col.filterable"/>

        <v-list-item
          v-if="lselected"
          key="CLEAR_FILTER"
          @click="lselected=undefined"
        >
          <v-list-item-icon>
            <v-icon color="error">
              {{ mdiClose }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Clear Filter
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-2" v-if="lselected" />

        <v-list-item-group
          v-model="lselected"
        >
          <v-list-item
            v-for="(item, i) in menuItems"
            :key="i"
            :value="item.value || item"
          >
            <v-list-item-icon>
              <v-icon
                v-if="lselected === item"
                color="success"
              >
                {{ mdiCheck }}
              </v-icon>
              <v-icon v-else/>
            </v-list-item-icon>
            <v-list-item-title v-if="item">
              {{ item }}
            </v-list-item-title>
            <v-list-item-title v-else>
              <i class="text-disabled">No value</i>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </th>
</template>

<style lang="css" scoped>
  .v-list-item--dense, .v-list--dense .v-list-item{
    min-height: 30px
  }
  .v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon{
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right:16px;
    margin-left:-4px;
  }
  .pointy{
    cursor: pointer;
  }
  table thead tr th,
  table tbody tr td {
    padding: 0px;
  }
</style>

<script>
import {
  mdiMinus,
  mdiArrowDown,
  mdiArrowUp,
  mdiFilterOutline,
  mdiMenuDown,
  mdiClose,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiCheck
} from "@mdi/js"

export default {
  props: {
    col: {
      type: Object,
      default () { return {} }
    },
    selected: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default () { return [] }
    },
    sort: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      showMenu: false,
      menuItems: null,
      mdiMinus,
      mdiArrowDown,
      mdiArrowUp,
      mdiFilterOutline,
      mdiMenuDown,
      mdiClose,
      mdiSortAlphabeticalAscending,
      mdiSortAlphabeticalDescending,
      mdiCheck
    }
  },
  computed: {
    lselected: {
      get () {
        return this.selected
      },
      set (selected) {
        this.showMenu = false
        this.$emit('filter', { field: this.col.field, value: selected })
      }
    },
    lsort: {
      get () {
        return this.sort
      },
      set (value) {
        this.showMenu = false
        this.$emit('sort', { value, field: this.col.field })
      }
    }
  },
  methods: {
    async getMenuItems () {
      if (this.col.filterable) {
        const { values } = await this.$store.dispatch('search-lists/get', this.col.field)
        this.menuItems = (values || []).filter (v => v)
      } else {
        this.menuItems = []
      }
    }
  },
  mounted () {
    this.getMenuItems()
  },
  watch: {
    col () {
      this.getMenuItems()
    }
  }

}
</script>
