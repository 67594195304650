<template>
  <div>
    <EditDialog ref="editDialog" />
    <Alert ref="alertDialog" />
    <v-container
      v-if="finding"
      fluid
      class="pa-0 text-body-1"
      :id="`findingSrc-${idkey}`"
    >
      <v-row
        class="d-none d-print-block"
      >
        <v-col
          cols="12"
          class=""
        >
          <div class="d-flex item">
            <div class="finding-label font-weight-bold">
              Finding File
            </div>
            <div class="finding-value">
              {{ finding.acbFindingFile }}
            </div>
          </div>
          <hr />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="mx-n4"
      >
        <v-col
          v-for="n in 2"
          class="px-5"
          cols="6"
          :key="n"
        >
          <template
            v-for="(field, i) in printableFields"
          >
            <div
              v-if="field.column === n"
              :key="i"
              :class="`d-flex align-center item pt-1 pb-1 px-0 mt-${field.mt} ${editing?'editing':''}`"
              @click="edit(field)"
            >
              <div class="finding-label font-weight-bold">
                <div :class="{
                  'primary--text': finding[field.field],
                  'text--caption': finding[field.field],
                  'text--disabled': !finding[field.field]
                }">
                  {{ field.field | humanizeFieldName }}
                </div>
              </div>
              <!-- <v-icon v-if="editing" small class="mr-2">{{ mdiPencil }}</v-icon> -->
              <div
                :class="{
                  'finding-value': true,
                  'text--disabled': !finding[field.field]
                }"
              >
                <div v-if="finding[field.field] instanceof Date"> {{ formatDate(finding[field.field]) }} </div>
                <div v-else-if="finding[field.field] === true"> Yes </div>
                <div v-else-if="finding[field.field] === false"> No </div>
                <div v-else> {{ finding[field.field] || '-'}} </div>
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row
        class="mx-n4 my-0"
        no-gutters
      >
        <v-col
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div
            :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
            @click="edit({
              field: 'acbFinding_Text',
              value: finding.acbFinding_Text
            })"
          >
            <div class="finding-label text--caption primary--text font-weight-bold">
              Finding Text
            </div>
            <slot name="acbFinding_Text" :finding="finding">
              <div class="finding-value">{{ finding.acbFinding_Text }}</div>
            </slot>
          </div>
        </v-col>

        <slot name="proposed" :finding="finding">
          <v-col
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div
              :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
              @click="edit({
                field: 'acbProposedCA',
                value: finding.acbProposedCA
              })"
            >
              <div class="finding-label text--caption primary--text font-weight-bold">
                Proposed Corrective Action / Root Cause Analysis
              </div>
              <div class="finding-value">{{ finding.acbProposedCA || '-' }}</div>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div
              :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
              @click="edit({
                field: 'acbProposedCompDate',
                value: finding.acbProposedCompDate
              })"
            >
              <div class="finding-label text--caption primary--text font-weight-bold">
                Proposed Completion Date
              </div>
              <div class="finding-value">{{
                finding.acbProposedCompDate
                ? formatDate(finding.acbProposedCompDate)
                : '-'
              }}</div>
            </div>
          </v-col>
        </slot>

        <slot name="cas" :finding="finding">
          <v-col
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div
              :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
              @click="edit({
                field: 'acbCA',
                value: finding.acbCA
              })"
            >
              <div class="finding-label text--caption primary--text font-weight-bold">
                Summary of Corrective Action Taken
              </div>
              <div class="finding-value">{{ finding.acbCA || '-' }}</div>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div
              :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
              @click="edit({
                field: 'acbCompDate',
                value: finding.acbCompDate
              })"
            >
              <div class="finding-label text--caption primary--text font-weight-bold">
                Completion Date
              </div>
              <div class="finding-value">{{
                finding.acbCompDate
                ? formatDate(finding.acbCompDate)
                : '-'
              }}</div>
            </div>
          </v-col>
        </slot>

        <slot name="closeddate" :finding="finding">
          <v-col
            v-if="finding.acbCloseDate"
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div
              :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
              @click="edit({
                field: 'acbCloseDate',
                value: finding.acbCloseDate
              })"
            >
              <div class="finding-label text--caption primary--text font-weight-bold">
                Closed Date
              </div>
              <slot name="acbCloseDate" :finding="finding">
                <div class="finding-value">{{ formatDate(finding.acbCloseDate) }}</div>
              </slot>
            </div>
          </v-col>
        </slot>

        <slot name="closedby" :finding="finding">
          <v-col
            v-if="finding.acbClosedBy"
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div class="d-flex px-0 item my-2">
              <div class="finding-label text--caption primary--text font-weight-bold">
                Closed By
              </div>
              <slot name="acbClosedBy" :finding="finding">
                <div class="finding-value">{{
                  finding.acbClosedBy
                }}</div>
              </slot>
            </div>
          </v-col>
        </slot>

        <slot name="notes" :finding="finding">
          <v-col
            v-if="finding.acbNotes"
            cols="12"
            class="px-5 my-0"
          >
            <div class="d-flex item" />
            <div
              :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
              @click="edit({
                field: 'acbNotes',
                value: finding.acbNotes
              })"
            >
              <div class="finding-label text--caption primary--text font-weight-bold">
                Notes
              </div>
                <div class="finding-value">{{finding.acbNotes || '-' }}</div>
            </div>
          </v-col>
        </slot>

        <v-col
          v-if="user.type === 'oc' && (finding.metadata.updated || {}).user"
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Edited By
            </div>

            <div class="finding-value">{{
              finding.metadata.updated.user.name
            }}</div>
          </div>
        </v-col>

        <v-col
          v-if="user.type === 'oc' && (finding.metadata.updated || {}).date"
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Edited Date
            </div>

            <div class="finding-value">{{
              finding.metadata.updated.date.toIACSformat(true)
            }}</div>
          </div>
        </v-col>

        <v-col
          v-if="user.type === 'oc' && finding.metadata.updated"
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div
            :class="`d-flex px-0 item my-2 ${editing?'editing':''}`"
            @click="edit({
                field: 'ocNotes',
                value: (finding.metadata.updated || {}).note || ''
              })"
          >
            <div class="finding-label text--caption primary--text font-weight-bold">
              Edit Notes
            </div>

            <div class="finding-value">{{
              finding.metadata.updated.note || '-'
            }}</div>
          </div>
        </v-col>

      </v-row>
    </v-container>

    <iframe
      v-if="finding"
      class="d-none"
      :id="`printFrame-${idkey}`"
      :name="`printFrame-${idkey}`"
    ></iframe>

  </div>
</template>

<style scoped>
  .item{
    font-size: 0.92rem;
    white-space: pre-line;
  }
  .item:not(:last-child){
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
  .finding-label{
    width: 125px;
    min-width: 125px;
    max-width: 125px;
  }
  .editing:hover{
    background: pink;
    cursor: pointer;
    user-select: none;
  }
</style>

<script>
import { mdiAlert, mdiPencil } from "@mdi/js"
// import { useGet } from 'feathers-vuex'
import { ref } from '@vue/composition-api'

import { getFileName } from '@/modules/finding'
import { fields } from "./field-definitions"
import EditDialog from './edit-dialog'

const defaultRedacted = ['_id', 'metadata', 'acbFindingFile']

export default {
  props: {
    finding: {
      type: Object,
      default () { return {} }
    },
    idkey: {
      type: String,
      default: '',
      required: true
    },
    redacted: {
      type: Array,
      default () { return [] }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditDialog
  },
  data () {
    return {
      mdiPencil
    }
  },
  computed: {
    printableFields () {
      const r = [...defaultRedacted, ...this.redacted]
      return fields
      .filter(f => {
        return r.indexOf(f.field) === -1
      })
    },
  },
  methods: {
    formatDate(date) {
      const d = d instanceof Date ? date : new Date(date)
      return d.toIACSformat()
    }
  },
  // setup(props, context) {
  setup(props, context) {
    const { $store } = context.root
    // const { User } = context.root.$FeathersVuex.api
    const editDialog = ref(null)
    const alertDialog = ref(null)
    const user = $store.state.auth.user || {}

    const edit = async (field) => {
      if (!props.editing) return

      const oldValue = (field.field === 'ocNotes')
                        ? ((props.finding.metadata || {}).updated || {}).note
                        : props.finding[field.field]

      const { result, newValue } = await editDialog.value.open(field, oldValue)
      if (result) {
        const update = { [field.field]: newValue }
        /*
          if any of the following fields are changed
          we need to recompute the finding filename
          and warn the user if this changes
        */
        if (['acbDate', 'acbAudit', 'acbFinding_Type', 'acbObs'].includes(field.field)){
          const tempFinding = { ...props.finding, [field.field]: newValue}
          const newFileName = `${getFileName(tempFinding)}.xml`
          let duplicates

          if (newFileName != props.finding.acbFindingFile) {
            // check that we are not making a duplicate file name
            duplicates = await $store.dispatch('findings/find',
              {query: {
                acbFindingFile: newFileName,
              },
                $select: ['acbFindingFile']
            })
            update.acbFindingFile = newFileName
          }

          if (duplicates?.total){
            return alertDialog.value.open(
              'No updates performed.',
              'A Finding with that name already exists.',
              {
                acceptLabel: 'OK',
                color: 'warning',
                icon: mdiAlert,
                width: 410
              }
            )
          }
        }
        if (field.field === 'ocNotes'){
          $store.dispatch('findings/update', [
            props.finding._id,
            {
              "$set": {
                "metadata.updated.note": newValue
              }
            }
          ])
        } else {
          await $store.dispatch('findings/update', [
            props.finding._id,
            {
              "$set": {
                "metadata.updated.date": Date.now(),
                "metadata.updated.user": user._id
              }
            }
          ])
          props.finding.patch({
            data: {
              ...update,
              oldValue: oldValue,
              kind: 'OC Edit'
            }
          })
        }

      }
    }

    // const id = ref(props.finding.metadata.updated.user)

    // const { item: editor } = useGet({
    //     model: User,
    //     id
    //   })

    return {
      alertDialog,
      editDialog,
      edit,
      user
    }
  }
}
</script>
