import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class Rejected extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static modelName = 'Rejected'

  // static instanceDefaults() {
  //   return {
  //   }
  // }
  static setupInstance(data) {
    if (data.finding.acbDate){
      data.finding.acbDate = new Date(data.finding.acbDate)
    }
    return data
  }
}
const servicePath = 'rejected'
const servicePlugin = makeServicePlugin({
  Model: Rejected,
  service: feathersClient.service(servicePath),
  servicePath,
  state: {
    count: 0
  },
  handleEvents: {
    /*
      re-query the rejected service for totals.
      keeps important totals up to date
    */
    created: (item, { model }) => {
      model.store.dispatch('rejected/fetchTotals')
      return true
    },
    patched: (item, { model }) => {
      model.store.dispatch('rejected/fetchTotals')
      return true
    },
    removed: (item, { model }) => {
      model.store.dispatch('rejected/fetchTotals')
      return true
    }
  },
  actions: {
    fetchTotals (context, params = { query: {} } ) {
      context.dispatch('count', params).then(count => {
        context.state.count = count
      })
    }
  },
  getters: {
    count: state => state.count
  }
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
