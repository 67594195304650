<template>
  <div class="fill-height" style="width:100%">
    <ul class='tabs'>
      <li
        v-for='(tab, i) in tabs'
        :key='tab.label'
        :class='{
          "active": (i == index),
          "iacs-error": tab.color === "error"
        }'
        :style='`z-index: ${ (i == index) ? tabs.length + 1 : tabs.length - i }`'
        @click='selectTab(i)'
      >
        <div
          class="tab-graphic"
        >
          <div
            v-if="(i == index)"
            class="tab-label text-no-wrap primary--text"
          >
            {{ tab.label }}
            <div
              v-if="tab.count"
              class="circle ml-2 primary white--text"
            >
              <span class="circle__content">{{ tab.count }}</span>
            </div>
          </div>
          <div
            v-else
            class="tab-label text-no-wrap text--disabled"
          >
            {{ tab.label }}
            <div
              v-if="tab.count"
              class="circle ml-2"
            >
              <span class="circle__content">{{ tab.count }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<style lang="css">
  .circle {
    display: inline-table;
    vertical-align: middle;
    min-width: 20px;
    padding:0 10px;
    height: 20px;
    background-color: rgba(0,0,0,0.125);
    border-radius: 99px;
  }
  .circle__content {
    font-size:14px;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    /*letter-spacing: -1px;*/
  }
  .count{
    color:white;
    font-size: 14px;
    font-weight: bold;
    background-color: crimson;
    /*padding: 0.1rem 0.4rem;*/
    line-height: 1px;
    border-radius: 99px;
    text-align: center;
    /*min-width: 18px;*/
    min-height: 20px;
    display: inline-block;
    /*vertical-align: top;*/
    letter-spacing: -1px;
    text-rendering: geometricPrecision;
  }
  ul.tabs{
    display: flex;
    list-style: none;
    border-bottom: 1px solid;
    border-color: rgb(172, 172, 172);
    height: 38px;
    margin: 0 0 0 6px;
    padding: 0;
    position: relative;
    z-index:0;
  }
  ul.tabs > li {
    display: flex;
    background: transparent;
    overflow:hidden;
    margin: 0 -31px 0 0;
    padding: 0;
    cursor: pointer;
  }
  ul.tabs > li .tab-label{
    margin: -3px 8px 2px;
    /*text-transform: uppercase;*/
  }
  ul.tabs > li > .tab-graphic{
    display: flex;
    align-items: center;
    background-color: rgb(240, 240, 240);
    background-clip: content-box;
    border-top: 1px solid transparent;
    border-left: 32px solid transparent;
    border-right: 32px solid transparent;
    border-bottom: 0 none;
    border-image-source: url('~@/assets/UI/tabs/tab.svg');
    border-image-slice: 1 32 0 32 fill;
    transform: translate(0px, 4px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  ul.tabs > li.iacs-error > .tab-graphic{
    border-image-source: url('~@/assets/UI/tabs/tab-error.svg');
  }
  ul.tabs > li.iacs-error .tab-label.text--disabled{
    color: red !important;
  }
  ul.tabs > li.active.iacs-error .tab-label{
    color: red !important;
  }
  ul.tabs > li:first-child .tab-graphic{
    border-left: 12px solid transparent;
    border-image: url('~@/assets/UI/tabs/tab-first.svg') 1 32 0 12 fill;
  }
  ul.tabs > li.active{
    overflow: visible;
  }
  ul.tabs > li.active .tab-graphic{
    border-image: url('~@/assets/UI/tabs/tab-active.svg') 1 32 0 32 fill;
    background-color: white;
    transform: translate(0px, 1px);
  }
  ul.tabs > li:first-child.active .tab-graphic{
    border-left: 12px solid transparent;
    border-image: url('~@/assets/UI/tabs/tab-first-active.svg') 1 32 0 12 fill;
  }
  ul.tabs > li.active .tab-label{
    margin-top: 1px;
  }
</style>

<script>
export default {
  props: {
    selectedIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      index: 0,
      tabs: [] // all of the tabs
    }
  },
  watch: {
    selectedIndex (newVal) {
      this.index = newVal
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.index = i
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>
