import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/home'
import Login from '../pages/login'
import PasswordReset from '../pages/password-reset'
import SingleFinding from '../pages/single-finding'

Vue.use(VueRouter)

const routes = [
  { path: '/finding/:filename', component: SingleFinding },
  { path: '/login', name: 'Login', component: Login },
  { path: '/password-reset/:uuid', component: PasswordReset },
  { path: '*', component: Home }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
