var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditDialog',{ref:"editDialog"}),_c('Alert',{ref:"alertDialog"}),(_vm.finding)?_c('v-container',{staticClass:"pa-0 text-body-1",attrs:{"fluid":"","id":("findingSrc-" + _vm.idkey)}},[_c('v-row',{staticClass:"d-none d-print-block"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"},[_c('div',{staticClass:"finding-label font-weight-bold"},[_vm._v(" Finding File ")]),_c('div',{staticClass:"finding-value"},[_vm._v(" "+_vm._s(_vm.finding.acbFindingFile)+" ")])]),_c('hr')])],1),_c('v-row',{staticClass:"mx-n4",attrs:{"no-gutters":""}},_vm._l((2),function(n){return _c('v-col',{key:n,staticClass:"px-5",attrs:{"cols":"6"}},[_vm._l((_vm.printableFields),function(field,i){return [(field.column === n)?_c('div',{key:i,class:("d-flex align-center item pt-1 pb-1 px-0 mt-" + (field.mt) + " " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit(field)}}},[_c('div',{staticClass:"finding-label font-weight-bold"},[_c('div',{class:{
                'primary--text': _vm.finding[field.field],
                'text--caption': _vm.finding[field.field],
                'text--disabled': !_vm.finding[field.field]
              }},[_vm._v(" "+_vm._s(_vm._f("humanizeFieldName")(field.field))+" ")])]),_c('div',{class:{
                'finding-value': true,
                'text--disabled': !_vm.finding[field.field]
              }},[(_vm.finding[field.field] instanceof Date)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.finding[field.field]))+" ")]):(_vm.finding[field.field] === true)?_c('div',[_vm._v(" Yes ")]):(_vm.finding[field.field] === false)?_c('div',[_vm._v(" No ")]):_c('div',[_vm._v(" "+_vm._s(_vm.finding[field.field] || '-')+" ")])])]):_vm._e()]})],2)}),1),_c('v-row',{staticClass:"mx-n4 my-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
            field: 'acbFinding_Text',
            value: _vm.finding.acbFinding_Text
          })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Finding Text ")]),_vm._t("acbFinding_Text",[_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbFinding_Text))])],{"finding":_vm.finding})],2)]),_vm._t("proposed",[_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
              field: 'acbProposedCA',
              value: _vm.finding.acbProposedCA
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Proposed Corrective Action / Root Cause Analysis ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbProposedCA || '-'))])])]),_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
              field: 'acbProposedCompDate',
              value: _vm.finding.acbProposedCompDate
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Proposed Completion Date ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbProposedCompDate ? _vm.formatDate(_vm.finding.acbProposedCompDate) : '-'))])])])],{"finding":_vm.finding}),_vm._t("cas",[_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
              field: 'acbCA',
              value: _vm.finding.acbCA
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Summary of Corrective Action Taken ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbCA || '-'))])])]),_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
              field: 'acbCompDate',
              value: _vm.finding.acbCompDate
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Completion Date ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbCompDate ? _vm.formatDate(_vm.finding.acbCompDate) : '-'))])])])],{"finding":_vm.finding}),_vm._t("closeddate",[(_vm.finding.acbCloseDate)?_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
              field: 'acbCloseDate',
              value: _vm.finding.acbCloseDate
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Closed Date ")]),_vm._t("acbCloseDate",[_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.formatDate(_vm.finding.acbCloseDate)))])],{"finding":_vm.finding})],2)]):_vm._e()],{"finding":_vm.finding}),_vm._t("closedby",[(_vm.finding.acbClosedBy)?_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{staticClass:"d-flex px-0 item my-2"},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Closed By ")]),_vm._t("acbClosedBy",[_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbClosedBy))])],{"finding":_vm.finding})],2)]):_vm._e()],{"finding":_vm.finding}),_vm._t("notes",[(_vm.finding.acbNotes)?_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){return _vm.edit({
              field: 'acbNotes',
              value: _vm.finding.acbNotes
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Notes ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.acbNotes || '-'))])])]):_vm._e()],{"finding":_vm.finding}),(_vm.user.type === 'oc' && (_vm.finding.metadata.updated || {}).user)?_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{staticClass:"d-flex px-0 item my-2"},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Edited By ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.metadata.updated.user.name))])])]):_vm._e(),(_vm.user.type === 'oc' && (_vm.finding.metadata.updated || {}).date)?_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{staticClass:"d-flex px-0 item my-2"},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Edited Date ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.metadata.updated.date.toIACSformat(true)))])])]):_vm._e(),(_vm.user.type === 'oc' && _vm.finding.metadata.updated)?_c('v-col',{staticClass:"px-5 my-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex item"}),_c('div',{class:("d-flex px-0 item my-2 " + (_vm.editing?'editing':'')),on:{"click":function($event){_vm.edit({
              field: 'ocNotes',
              value: (_vm.finding.metadata.updated || {}).note || ''
            })}}},[_c('div',{staticClass:"finding-label text--caption primary--text font-weight-bold"},[_vm._v(" Edit Notes ")]),_c('div',{staticClass:"finding-value"},[_vm._v(_vm._s(_vm.finding.metadata.updated.note || '-'))])])]):_vm._e()],2)],1):_vm._e(),(_vm.finding)?_c('iframe',{staticClass:"d-none",attrs:{"id":("printFrame-" + _vm.idkey),"name":("printFrame-" + _vm.idkey)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }