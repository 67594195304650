const findingTypes = {
  'Non-Conformity': 'NC',
  'No Finding': 'NF',
  'Observation': 'OB'
}

// searchOperators
const so = {
  like: { text: 'Contains', value: '~' },
  eq: { text: 'Equals', value: 'eq' },
  ne: { text: 'Not Equals', value: 'ne' },
  gt: { text: 'Greater Than', value: 'gt' },
  lt: { text: 'Less Than', value: 'lt' },
}

const soDate = [so.eq, so.ne, so.gt, so.lt]
const soText = [so.eq, so.ne, so.like]
const soLike = [so.like]
const soEq = [so.eq]

const fields = [
  {
    field: 'metadata',
    feathers: { default: {}  }
  },
  {
    field: 'acbACB',
    feathers: { default: '' },
    shortname: 'ACB',
    longname: 'ACB',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'acbAudit',
    feathers: { default: '' },
    shortname: 'Audit Ref.',
    longname: 'Audit Reference',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
  },
  {
    field: 'acbAuditor',
    feathers: { default: '' },
    shortname: 'Auditor',
    longname: 'Auditor',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'acbAuditType',
    feathers: { default: '' },
    shortname: 'Audit Type',
    longname: 'Audit Type',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'acbCA',
    feathers: { default: '' },
    shortname: 'Summary of CAs',
    longname: 'Summary of Corrective Action Taken',
    searchable: true,
    searchOperators: soLike,
    sortable: true,
    filterable: true,
  },
  {
    field: 'acbClass',
    feathers: { default: '' },
    shortname: 'Class',
    longname: 'Class Society',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'acbClosedBy',
    feathers: { default: null  },
    shortname: 'Closed By',
    longname: 'Closed By',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'acbCloseDate',
    feathers: { default: null  },
    shortname: 'Closed',
    longname: 'Closed Date',
    searchable: true,
    searchOperators: soDate,
    listable: true,
    sortable: true,
    sort: {
      asc: 'Earliest First',
      desc: 'Latest First'
    },
    // for display in data tables
    valueFunction: (item, field) => {
      const d = item[field]
      if (!d) return '-'
      return d.toIACSformat()
    }
  },
  {
    field: 'acbCompDate',
    feathers: { default: null  },
    shortname: 'Completed',
    longname: 'Completion Date',
    searchable: true,
    searchOperators: soDate,
    listable: true,
    sortable: true,
    sort: {
      asc: 'Earliest First',
      desc: 'Latest First'
    },
    // for display in data tables
    valueFunction: (item, field) => {
      const d = item[field]
      if (!d) return '-'
      return d.toIACSformat()
    }
  },
  {
    field: 'acbDate',
    feathers: { default: '' },
    shortname: 'Audit Date',
    longname: 'Audit Date',
    searchable: true,
    searchOperators: soDate,
    listable: true,
    sortable: true,
    sort: {
      asc: 'Earliest First',
      desc: 'Latest First'
    },
    // for display in data tables
    valueFunction: (item, field) => {
      const d = item[field]
      if (!d) return '-'
      return d.toIACSformat()
    }
  },
  {
    field: 'acbDepartment',
    feathers: { default: '' },
    shortname: 'Audit Details',
    longname: 'Audit Details',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbFinding_Cat',
    feathers: { default: '' },
    shortname: 'Finding Cat.',
    longname: 'Finding Category',
    searchable: true,
    searchOperators: soText,
    listable: true,
    filterable: true,
    truncate: true
  },
  {
    field: 'acbFinding_Text',
    feathers: { default: '' },
    shortname: 'Finding Text',
    longname: 'Finding Text',
    searchable: true,
    searchOperators: soLike,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbFinding_Type',
    feathers: { default: '' },
    shortname: 'Type',
    longname: 'Finding Type',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true,
    // for display in data tables
    valueFunction: (item, field) => {
      return findingTypes[item[field]]
    }
  },
  {
    field: 'acbFindingFile',
    feathers: { default: '' },
    shortname: 'File Name',
    longname: 'File Name',
    searchable: true,
    searchOperators: soLike,
    listable: true,
    truncate: true,
    sortable: true,
    // for display in data tables
    valueFunction: (item, field) => {
      return (item[field]||'No File Name').replace(/.xml$/, '')
    }
  },
  {
    field: 'acbFlag',
    feathers: { default: '' },
    shortname: 'Flag',
    longname: 'Flag',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbIQMSR',
    feathers: { default: '' },
    shortname: 'QMSR Para. No.',
    longname: 'QMSR Paragraph Number',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbLinks',
    feathers: { default: '' },
    shortname: 'Links',
    longname: 'Links',
    searchable: true,
    listable: true,
    sortable: false
  },
  {
    field: 'acbLocation',
    feathers: { default: '' },
    shortname: 'Location',
    longname: 'Location',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true
  },
  {
    field: 'acbNotes',
    feathers: { default: '' },
    shortname: 'Notes',
    longname: 'Notes',
    searchable: true,
    sortable: true
  },
  {
    field: 'acbObs',
    feathers: { default: '' },
    shortname: 'Finding No.',
    longname: 'Finding Number',
    searchable: true,
    searchOperators: soText,
    listable: true,
    filterable: true
  },
  {
    field: 'acbOffNo',
    feathers: { default: '' },
    shortname: 'IMO/Hull/Job No.',
    longname: 'IMO/Hull/Job No.',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbPR',
    feathers: { default: '' },
    shortname: 'PR No.',
    longname: 'PR Number',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbProposedCA',
    feathers: { default: '' },
    shortname: 'Proposed CAs/RCA',
    longname: 'Proposed Corrective Action / Root Cause Analysis',
    searchable: true,
    searchOperators: soLike,
    sortable: true
  },
  {
    field: 'acbProposedCompDate',
    feathers: { default: null  },
    shortname: 'Proposed Date',
    longname: 'Proposed Completion Date',
    searchable: true,
    searchOperators: soDate,
    listable: true,
    sortable: true,
    sort: {
      asc: 'Earliest First',
      desc: 'Latest First'
    },
    // for display in data tables
    valueFunction: (item, field) => {
      const d = item[field]
      if (!d) return '-'
      return d.toIACSformat()
    }
  },
  {
    field: 'acbRemote',
    feathers: { default: false  },
    shortname: 'Remote Audit',
    longname: 'Remote Audit',
    searchable: true,
    searchOperators: soEq,
    listable: true,
    sortable: true,
    filterable: true,
    // for display in data tables
    valueFunction: (item, field) => {
      return item[field] ? 'Yes' : 'No'
    }
  },
  {
    field: 'acbRO_Code',
    feathers: { default: '' },
    shortname: 'RO Code',
    longname: 'RO Code',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbSystem',
    feathers: { default: '' },
    shortname: 'System Related',
    longname: 'System Related',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbTechnical',
    feathers: { default: '' },
    shortname: 'Technical',
    longname: 'Technical',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbUR',
    feathers: { default: '' },
    shortname: 'UR No.',
    longname: 'UR Number',
    searchable: true,
    searchOperators: soText,
    listable: true,
    truncate: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'acbVCAType',
    feathers: { default: '' },
    shortname: 'VCA Type',
    longname: 'VCA Type',
    searchable: true,
    searchOperators: soText,
    listable: true,
    sortable: true,
    filterable: true
  },
  {
    field: 'ocNotes',
    shortname: 'OC Notes',
    longname: 'OC Notes',
  }
]

const dictionary = fields.reduce((a, f) => ({ ...a, [f.field]: f }), {})
const schema = fields.filter(f => f.searchable)
const searchable = sortfield => fields.filter(f => f.searchable).sort((a, b) => a[sortfield] > b[sortfield] ? 1 : -1)
const listable = sortfield => fields.filter(f => f.listable).sort((a, b) => a[sortfield] > b[sortfield] ? 1 : -1)

export {
  dictionary,
  fields,
  listable,
  schema,
  searchable
}
