import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

import router from '../../router'

class User extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'User'
  // Define default properties here
  static instanceDefaults() {
    return {
      assignedTo: [],
      canCloseFindings: false,
      cs: {},
      email: '',
      enabled: false,
      fs: {},
      name: '',
      password: '',
      role: '',
      type: ''
    }
  }

  static setupInstance(data) {
    return data
  }
}
const servicePath = 'users'
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath,
  // Setup the client-side event listeners.
  // these DO fire when something is instigated elsewhere
  handleEvents: {
    patched: (item, { model }) => {
      // if a user account is disabled, force a logout
      const user = model.store.state.auth.user
      if (!item.enabled && user._id === item._id) {
        return model.store.dispatch('auth/logout')
      } else {
        return true
      }
    },
    removed: (item, { model }) => {
      // if a user account is removed
      const user = model.store.state.auth.user
      if (user._id === item._id) {
        //delete cookie
        document.cookie = `feathers-jwt=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        // delete local storage entry
        feathersClient.get('storage').removeItem("feathers-jwt")
        // redirect to login
        router.replace({name: 'Login'})
      } else {
        return true
      }
    }
  }
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      context => {
        if (context.data.type !== 'cs') {
          delete context.data.cs
        }
        if (context.data.type !== 'fs') {
          delete context.data.fs
        }
      }
    ],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
