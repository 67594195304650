import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class Submission extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static modelName = 'Submission'

  // model defaults
  static instanceDefaults() {
    return {
      uploads: [],
      metadata: {
        created: {
          date: Date.now(),
          browser: {
            platform: navigator.platform,
            language: navigator.language,
            userAgent: navigator.userAgent
          }
        }
      }
    }
  }

  static setupInstance(data) {
    data.uploads.forEach(u => {
      if (u.acbDate){
        u.acbDate = new Date(u.acbDate)
      }
    })
    return data
  }
}

const servicePath = 'submissions'
const servicePlugin = makeServicePlugin({
  Model: Submission,
  service: feathersClient.service(servicePath),
  servicePath,
  state: {
    counts: {
      imported: 0,
      unread: 0
    }
  },
  // Setup the client-side event listeners.
  // these DO fire when something is instigated elsewhere
  handleEvents: {
    /*
      re-query the submissions service for totals.
      keeps important totals up to date
    */
    created: (item, { model }) => {
      model.store.dispatch('submissions/fetchTotals')
      return true
    },
    patched: (item, { model }) => {
      model.store.dispatch('submissions/fetchTotals')
      return true
    }
  },
  actions: {
    fetchTotals (context) {
      let params = { query: { read: true } }
      context.dispatch('count', params).then(count => {
        context.state.counts.imported = count
      })
      params = { query: { read: false } }
      context.dispatch('count', params).then(count => {
        context.state.counts.unread = count
      })
    }
  },
  getters: {
    counts: state => state.counts
  }
})

// Setup the client-side Feathers hooks.
// these DO NOT fire when something is instigated elsewhere
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
