<template>
  <v-footer
    app
    :color="color"
    dark
    height="32"
    min-height="32"
    padless
  >
    <div class="d-flex text--caption flex-grow-1 px-5 py-1 justify-space-between">
      <div>{{ $store.state.footer }}</div>
      <div class="d-flex justify-space-between"
        v-if="nowTime !=''"
      >
        <div>{{ nowDay }}</div>
        <div class="mx-5" style="border-left:1px solid white" />
        <div>{{ nowDate }}</div>
        <div class="mx-5" style="border-left:1px solid white" />
        <div class="font-weight-black">{{ nowTime }}</div>
      </div>
    </div >
  </v-footer>
</template>

<script>
const SECOND = 1000
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      nowDate: 0,
      nowTime: 0,
      nowDay: ''
    }
  },
  mounted () {
    this.updateDateTime()
  },
  beforeDestroy () {
    window.clearTimeout(this.$options.timer)
  },
  methods: {
    updateDateTime () {
      const now = new Date()
      this.nowDate = now.toIACSformat()
      this.nowDay = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][now.getDay()]
      this.nowTime = now.toLocaleTimeString().toLowerCase().substr(0,5)
      this.$options.timer = window.setTimeout(this.updateDateTime, SECOND)
    }
  }
}
</script>
