import store from '../../store'

import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class Findings extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static modelName = 'Finding'

  // Define default properties here
  static instanceDefaults() {
    // need this to force local store update !
    // seems any field that's not in the initial query
    // triggers this.
    return {
      acbACB: '',
      acbAudit: '',
      acbAuditor: '',
      acbAuditType: '',
      acbCA: '',
      acbClass: '',
      acbCloseDate: null,
      acbCompDate: null,
      acbDate: '',
      acbDepartment: '',
      acbFinding_Cat: '',
      acbFinding_Text: '',
      acbFinding_Type: '',
      acbFindingFile: '',
      acbFlag: '',
      acbIQMSR: '',
      acbKey: '',
      acbLinks: '',
      acbLocation: '',
      acbLocked: '',
      acbNotes: '',
      acbObs: '',
      acbOffNo: '',
      acbPR: '',
      acbProposedCA: '',
      acbProposedCompDate: null,
      acbRemote: false,
      acbRO_Code: '',
      acbSystem: '',
      acbTechnical: '',
      acbUR: '',
      acbVCAType: '',
      metadata: {
        updated: {
      //     date: '',
      //     user: '',
      //     note: ''
        },
      }
    }
  }
  static setupInstance(data) {
    if (data.acbDate) data.acbDate = new Date(data.acbDate)
    if (data.acbCloseDate) data.acbCloseDate = new Date(data.acbCloseDate)
    if (data.acbCompDate) data.acbCompDate = new Date(data.acbCompDate)
    if (data.acbProposedCompDate) data.acbProposedCompDate = new Date(data.acbProposedCompDate)
    if (data.metadata && data.metadata.updated && data.metadata.updated.date) data.metadata.updated.date = new Date(data.metadata.updated.date)
    return data
  }
}
const servicePath = 'findings'
const servicePlugin = makeServicePlugin({
  Model: Findings,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [
    ],
    find: [
      context => {
        /*
          restrict certain user types here, append
          additional query params here.
        */
        const { params } = context
        const { user } = params

        /*
          auditors can only see findings in their !selected!
          CS. auditors can be in >1 CS and can
          switch via their user menu.

          so, send the selected CS index to the server.
          the server knows the auditor's CS.
          to prevent auditors from querying beyond their scope
          the server will add the relevant CS constraint
        */

        if (user.type === 'ac'){
          params.query.$cs = store.state.acUserSelectedCS
        }
      }
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [
      context => {
        const { params } = context
        // delete any server-side query params
        params.query.$cs = null
        delete params.query.$cs
      }
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
