<template>
  <router-view />
</template>

<script>
import { computed, onMounted, watch } from '@vue/composition-api'
import feathersClient from './feathers-client'
import router from '@/router'

export default {
  name: 'App',
  setup(props, context) {
    const { $store, $router } = context.root
    // this watches the authorised user object.
    // used to handle the logout redirection.
    const { currentRoute } = $router
    const route = computed(() => {
      return currentRoute.path
    })
    watch(
      () => $store.state.auth.user,
      user => {
        const { currentRoute } = $router
        if (!user) {
          /*
            when the user logs out, reload the page.
            this clears the local vuex data cache
            and user will be redirected to login page
            by the authentication hooks.

            maybe replace the route to prevent redirection
            to previous page after re-login
          */
          // console.log('LOGOFF')

          //delete cookie
          document.cookie = `feathers-jwt=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`

          // delete local storage entry
          feathersClient.get('storage').removeItem("feathers-jwt")

          if (currentRoute.path !== '/') {
            $router.replace('/')
          }
          location.reload()
        } else {
          /*
             This triggers on login success OR if the user
             loads a protected page and their JWT is
             reauthenticated.
             Only redirect if we're currenly on the login page.
          */
          if (currentRoute.name === 'Login') {
            /*
              redirect to the rewuested page or the home page.
              the page will dynamically load the components
              appropriate to the user type.
            */
            $router.replace(currentRoute.query.r || '/')
          }
        }
      },
      { lazy: true }
    )

    // Attempt jwt auth when the app mounts at some random page.
    // If authentication fails then redirect to the login page.
    onMounted(() => {
      $store.dispatch('auth/authenticate').catch(error => {
        if (!error.message.includes('Could not find stored JWT')) {
          const { currentRoute } = router
          // this route is unprotected, obviously
          if (currentRoute.path.match(/password-reset/)){
            return
          }
          // check not already on the login page
          if (currentRoute.name !== 'Login') {
            // skip navigation if already there
            if (currentRoute.path !== '/') {
              $router.replace({ name: 'Login', query: { r: currentRoute.path } })
            } else {
              $router.replace({ name: 'Login' })
            }
          }
        }
      })
    })

    return { route }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
