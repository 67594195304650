import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class SearchList extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'SearchList'
}
const servicePath = 'search-lists'
const servicePlugin = makeServicePlugin({
  Model: SearchList,
  service: feathersClient.service(servicePath),
  servicePath,
  debug: true
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [
      context => {
        // need to convert acbRemote from true/false to Yes/No for display
        if (context.id === 'acbRemote') {
          context.result.values = ['Yes', 'No']
        }
        return context
      }
    ],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
