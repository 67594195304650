import Vue from 'vue'
import idb from '@/api/index-db'

const state = () => ({
  uploads: []
})

const actions = {
  async fetch({ state }) {
    let uploads = await idb.getUploads()
    state.uploads = []
    uploads.forEach(c => {
      state.uploads.push(c)
    })
  },
  async queue({ dispatch }, upload) {
    await idb.saveUpload(upload)
    await dispatch('fetch')
  },
  async unqueue({ dispatch }, upload) {
    await idb.deleteUpload(upload)
    await dispatch('fetch')
  },
  async upload( { dispatch, rootState } , upload) {

    /*
      if online, send.
      should really not be reachable if offline but nice to do a check here.
    */
    if (!rootState.socket) {
      return alert('you are offline')
    }

    // set some useful metadata
    const metadata = {
      uploaded: {
        date: Date.now(),
        browser: {
          platform: navigator.platform,
          language: navigator.language,
          userAgent: navigator.userAgent
        }
      }
    }

    // create a new Submission instance with the data
    const { Submission } = Vue.$FeathersVuex.api
    const sub = new Submission({ ...upload, metadata, id: null })

    try {
      // use the socket to save the submission
      await sub.save()
    } catch ({ message, filename }) {
      /*
        in the event of a duplicate file name error, move the submission
        findings back into local draft findings, then unqueue the submission
      */
      if (message === 'Duplicate Filename'){
        await Promise.all(upload.uploads.map(finding => {
          const clean = { ...finding }
          clean.acbDate = clean.acbDate.toISOString()
          dispatch('LocalFindings/saveFinding', clean, {root:true})
        }))
        await dispatch('unqueue', upload)
      }
      return Promise.reject({ message, filename })
    }

    // if no error, // store the metadata on the local upload object
    upload.metadata = metadata
    await idb.saveUpload(upload)

    // update the vuex store
    await dispatch('fetch')
  },
}

const getters = {
  queued: state => {
    return state.uploads.filter(u => !u.metadata)
  },
  uploaded: state => {
    return state.uploads.filter(u => u.metadata ).sort((a, b) => a.id < b.id ? 1 : -1)
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state
}
