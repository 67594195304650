import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class ValueList extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'ValueList'
}
const servicePath = 'value-lists'
const servicePlugin = makeServicePlugin({
  Model: ValueList,
  service: feathersClient.service(servicePath),
  servicePath,
  debug: true
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
