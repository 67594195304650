<template>
  <v-autocomplete
    v-model="selected"
    class="select-with-background"
    :clearable="!required"
    dense
    hide-details
    outlined
    return-object
    single-line
    :disabled="disabled"
    :items="items"
    :label="label"
    :menu-props="{ dense: true, offsetY: true, ripple: false, transition: false}"
    :readonly="readonly"
  ></v-autocomplete>
</template>

<style>
.select-with-background .v-input__slot {
  background: white !important;
}
.v-select__selection {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}
.v-select__selections {
  width: 100%;
}
.v-select__selections input{
  display: none;
}
.v-select__selections .v-label{
  top: 8px;
}
.v-input__append-inner{
  margin-top: 6px !important;
}
</style>

<script>
export default {
  props: {
    field: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default () { return ['Options not loaded'] }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Object]
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        const field = this.field
        this.$emit('change', { field, value })
      },
    }
  }
}
</script>
