<template>
  <v-app>
    <v-main class="page-with-background">
      <v-container
        fill-height
        fluid
        class="pb-16"
      >
        <v-row
          align="center"
          justify="center"
          class="mt-0 mb-16"
        >
          <v-col>
            <v-card
              class="mx-auto pa-10"
              max-width="366"
              elevation="10"
              color="primary"
            >
              <div
                class="d-flex flex-column align-center mt-3 mb-9"
              >
                <Logo
                  inverted
                  :width="240"
                />
                <div
                  class="text-h5 mt-3 white--text text-center"
                >
                  <span v-if="!mode"> ARM Login </span>
                  <span v-else> Password Reset Request </span>
                </div>
              </div>
              <Login v-if="!mode" @change="mode = 1"/>
              <Reset v-else @change="mode = 0"/>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      padless
      app
      style="background-color:transparent"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <small class="text--secondary">
          {{ $store.state.footer }}
        </small>
      </v-col>
    </v-footer>
  </v-app>
</template>

<style>
  .page-with-background {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTkyMCAxNDMwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOTIwIDE0MzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4gPHN0eWxlIHR5cGU9InRleHQvY3NzIj4gLnN0MXtmaWxsOiNERUVGRjk7fSAuc3Qye2ZpbGw6I0VFRjdGQzt9IDwvc3R5bGU+IDxnIGlkPSJMYXllcl8xIj4gPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIxLDE4My4zIDI0My44LDI4Ny4xIDEsMzYwICAiLz4gPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIxMDA4LjYsMjQwIDEyMjQuNSwyMTQgMTMxNy40LDI4MS45ICAiLz4gPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIxNTQ0LjIsMzEyLjcgMTkyMSwxODMuMyAxOTIxLDMxNCAxODE2LjYsMzQ3LjggICIvPiA8cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjAsMzYwIDU4MiwxODAgMTgxMywzNDMgMTkyMSwzMTMgMTkyMSwxNDMwIDAsMTQzMCAgIi8+IDwvZz4gPC9zdmc+');
    background-color: #fff;
    background-position: 0 60vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
</style>

<script>
import Logo from '@/components/Logo.vue'

import Login from './views/login'
import Reset from './views/password-reset'

export default {
  name: 'Gatekeeper',
  components: {
    Login,
    Logo,
    Reset
  },
  data() {
    return {
      mode: 0
    }
  }
}
</script>
