<template>
  <v-app v-if="user && user.enabled">
    <v-container
      v-if="!loaded"
      class="primary"
      fill-height
      fluid
      id="loading-state"
    >
      <v-row
        alignment="center"
        justify="center"
        class="mt-n16 mb-6"
      >
        <v-col
          cols="auto"
          class="d-flex flex-column align-center"
        >
          <v-progress-circular
            :size="180"
            :width="6"
            color="white"
            indeterminate
          >
            Loading ARM
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <!-- can use <component :is="" /> here ??? -->
    <oc-layout v-if="userType === 'oc'" v-show="loaded" @loaded="onLoad" />
    <ac-layout v-if="userType === 'ac'" v-show="loaded" @loaded="onLoad" />
    <cs-layout v-if="userType === 'cs'" v-show="loaded" @loaded="onLoad" />
    <fs-layout v-if="userType === 'fs'" v-show="loaded" @loaded="onLoad" />
    <ob-layout v-if="userType === 'ob'" v-show="loaded" @loaded="onLoad" />
    <as-layout v-if="userType === 'as'" v-show="loaded" @loaded="onLoad" />

    <vue-snotify></vue-snotify>

    <Footer v-if="loaded"/>
  </v-app>
</template>

<style>
@import "~vue-snotify/styles/material.css"
</style>

<style scoped>
#loading-state{
  opacity: 0.75;
}
</style>


<script>
import { ref, watch } from '@vue/composition-api'
import Footer from '@/components/UI/footer'

export default {
  name: 'HomePageWrapper',
  components: { Footer },
  computed: {
    user() {
      return this.$store.state.auth.user
    }
  },
  setup(props, context) {
    const { $store } = context.root
    const loaded = ref(false)
    const userType = ref(($store.state.auth.user || {}).type || '')

    watch(
      () => $store.state.auth.user,
      user => {
        if (!user) return
        if (!user.enabled) {
          // guard
          $store.dispatch('auth/logout')
        }
        userType.value = user.type
      },
      // { lazy: true }
    )

    function onLoad() {
      setTimeout(() => (loaded.value = true), $store.state.loginDelay)
    }

    function logout() {
      return $store.dispatch('auth/logout')
    }

    return {
      loaded,
      logout,
      onLoad,
      userType
    }
  }
}
</script>
