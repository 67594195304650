<template>
  <svg
    version="1.1"
    id="IACS_LOGO"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 161 48.8"
    :width="width"
    :height="height"
    :class="{ inverted: inverted }"
  >
    <rect y="4" width="8.3" height="40.9"/>
    <path d="M27.1,31.4c-1.2-6.1-2.1-12.4-2.7-18.9c-0.8,6-1.7,12.3-2.8,18.9H27.1z M20.3,4h8.5l9.1,40.9h-8.1l-1.4-7.2  h-8.2l-1.3,7.2h-8.1L20.3,4z"/>
    <path d="M55,45.4c-10.3,0-17.6-8.1-17.6-20.6c0-13.1,7.2-21.3,17.2-21.3c1.8,0,3.5,0.3,5.1,1v7.6  c-1.6-0.6-3-0.9-4.5-0.9c-5.2,0-9.2,4.9-9.2,13.2c0,8,4.1,12.8,9.3,12.8c1.4,0,2.9-0.3,4.3-1v8.2C58.1,45.1,56.5,45.4,55,45.4"/>
    <path d="M63.6,35c2,1.8,4,2.7,6,2.7c2.8,0,4.5-1.6,4.5-4.1c0-2.9-2.2-4-4.6-6.2c-4.3-3.9-6.5-7-6.5-12.1  c0-7,4.4-11.8,10.8-11.8c2.3,0,4.5,0.7,6.9,2v7.2c-2.3-1.1-4-1.8-5.4-1.8c-2.4,0-4,1.6-4,4c0,2.8,1.9,4.5,4.8,6.9  c4,3.4,6.5,5.8,6.5,11.5c0,7.3-4.4,12-11.7,12c-2.8,0-5-0.6-7-1.9V35z"/>
    <g>
      <rect x="90.8" y="2.6" width="1.6" height="7.5"/>
      <path d="M95.2,4.7v0.8h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1,0.2   c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.3,0.1,0.6,0.1,0.9v3.3h-1.5V7c0-0.4-0.1-0.8-0.2-1   c-0.1-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1.2v2.8h-1.5V4.7H95.2z"/>
      <path d="M102.9,4.7v1h-1.1v2.7c0,0.3,0,0.4,0.1,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0v1.1   c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4   c-0.1-0.2-0.1-0.4-0.1-0.7V5.7h-0.9v-1h0.9V3h1.5v1.6H102.9z"/>
      <path d="M105.2,8.8c0.2,0.2,0.5,0.3,1,0.3c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3-0.3,0.4-0.5h1.3c-0.2,0.6-0.5,1.1-1,1.4   c-0.4,0.3-1,0.4-1.6,0.4c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.5-0.5-0.9c-0.1-0.3-0.2-0.7-0.2-1.1   c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.3-0.7,0.6-0.9c0.2-0.3,0.5-0.5,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.2,0.3   c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.1,0.8,0.1,1.2h-3.9C104.9,8.2,105,8.6,105.2,8.8 M106.9,5.9   c-0.2-0.2-0.4-0.3-0.8-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4h2.4   C107.2,6.4,107.1,6.1,106.9,5.9"/>
      <path d="M111,4.7v1h0c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.2   c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0.1V6c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7,0.1   c-0.2,0.1-0.3,0.2-0.5,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.2-0.1,0.4-0.1,0.7v2.4h-1.5V4.7H111z"/>
      <path d="M115.3,4.7v0.8h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1,0.2   c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.3,0.1,0.6,0.1,0.9v3.3h-1.5V7c0-0.4-0.1-0.8-0.2-1   c-0.1-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1.2v2.8h-1.5V4.7H115.3z"/>
      <path d="M120,6.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1   c0.3,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2s0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8v2.8c0,0.2,0,0.5,0,0.7c0,0.2,0.1,0.4,0.1,0.5   h-1.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.4-0.8,0.5s-0.6,0.1-1,0.1c-0.3,0-0.5,0-0.7-0.1   c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.2-0.4,0.4-0.5   c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.7-0.1s0.4-0.1,0.7-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.3-0.1,0.4-0.2   c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0   c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.6H120z M123.4,7.4c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0.1-0.3,0.1s-0.2,0-0.3,0.1   c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3   c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3,0c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.4   c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.3V7.4z"/>
      <path d="M129,4.7v1H128v2.7c0,0.3,0,0.4,0.1,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0v1.1   c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4   c-0.1-0.2-0.1-0.4-0.1-0.7V5.7h-0.9v-1h0.9V3h1.5v1.6H129z"/>
      <path d="M129.8,2.6h1.5v1.2h-1.5V2.6z M131.3,10.1h-1.5V4.7h1.5V10.1z"/>
      <path d="M132.5,6.2c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.2,0.2   c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.3,0.6-0.6,0.9   c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-0.9   c-0.1-0.3-0.2-0.7-0.2-1.2C132.3,6.9,132.3,6.5,132.5,6.2 M133.8,8c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4   c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6   c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4   c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6S133.8,7.8,133.8,8"/>
      <path d="M140.2,4.7v0.8h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1,0.2   c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.3,0.1,0.6,0.1,0.9v3.3h-1.5V7c0-0.4-0.1-0.8-0.2-1   c-0.1-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.2v2.8h-1.5V4.7H140.2z"/>
      <path d="M145,6.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1   c0.3,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2s0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8v2.8c0,0.2,0,0.5,0,0.7c0,0.2,0.1,0.4,0.1,0.5   h-1.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.4-0.8,0.5s-0.6,0.1-1,0.1c-0.3,0-0.5,0-0.7-0.1   c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.2-0.4,0.4-0.5   c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.7-0.1s0.4-0.1,0.7-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.3-0.1,0.4-0.2   c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0   c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.6H145z M148.4,7.4c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0.1-0.3,0.1s-0.2,0-0.3,0.1   c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3   c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3,0c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.4   c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.3V7.4z"/>
      <rect x="151.1" y="2.6" width="1.5" height="7.5"/>
      <path d="M95.1,14.2l2.8,7.5h-1.7L95.6,20h-2.8l-0.6,1.7h-1.6l2.8-7.5H95.1z M95.2,18.8l-0.9-2.7h0l-1,2.7H95.2z"/>
      <path d="M99.6,20.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0   c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.4-0.5c-0.3-0.1-0.7-0.2-1.2-0.3   c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.2-0.9   c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3   c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.3,0.8h-1.4c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0   c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2   c0.2,0.1,0.3,0.1,0.5,0.1s0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.4   c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.4-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1   c-0.3,0-0.6,0-0.9-0.1s-0.6-0.2-0.8-0.3s-0.4-0.3-0.6-0.6s-0.2-0.5-0.2-0.9h1.4C99.5,20.1,99.6,20.2,99.6,20.3"/>
      <path d="M105.3,20.3c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0   c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.4-0.5c-0.3-0.1-0.7-0.2-1.2-0.3   c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.2-0.9   c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3   c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.3,0.8h-1.4c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0   c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2   c0.2,0.1,0.3,0.1,0.5,0.1s0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.4   c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.4-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1   c-0.3,0-0.6,0-0.9-0.1s-0.6-0.2-0.8-0.3s-0.4-0.3-0.6-0.6c-0.1-0.2-0.2-0.5-0.2-0.9h1.4C105.2,20.1,105.2,20.2,105.3,20.3"/>
      <path d="M109.6,17.8c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.2,0.2   c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.3,0.6-0.6,0.9   c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-0.9   c-0.1-0.3-0.2-0.7-0.2-1.2C109.4,18.6,109.5,18.2,109.6,17.8 M111,19.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4   c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6   c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4   c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6S111,19.4,111,19.6"/>
      <path d="M118.6,17.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6   c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.6-0.1,0.8-0.3   c0.2-0.2,0.3-0.5,0.4-0.8h1.4c-0.1,0.7-0.4,1.3-0.8,1.6c-0.5,0.4-1,0.6-1.8,0.6c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6   c-0.2-0.2-0.4-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.3,0.5-0.5,0.9-0.6   c0.3-0.1,0.7-0.2,1.2-0.2c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,0.8,0.4s0.4,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.3,0.9h-1.5   C119.6,17.6,119.2,17.3,118.6,17.3"/>
      <path d="M122,14.2h1.5v1.2H122V14.2z M123.5,21.7H122v-5.4h1.5V21.7z"/>
      <path d="M124.6,17.9c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1   c0.3,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8v2.8c0,0.2,0,0.5,0,0.7   c0,0.2,0.1,0.4,0.1,0.5h-1.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.4-0.8,0.5s-0.6,0.1-1,0.1   c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0.1-0.6,0.2-0.8   c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.7-0.1s0.4-0.1,0.7-0.1c0.2,0,0.4-0.1,0.6-0.1   c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1   c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.6H124.6z M128.1,19c-0.1,0.1-0.1,0.1-0.2,0.1   c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1   c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1   c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.3V19z"/>
      <path d="M133.6,16.3v1h-1.1v2.7c0,0.3,0,0.4,0.1,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0v1.1   c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4   c-0.1-0.2-0.1-0.4-0.1-0.7v-3.2h-0.9v-1h0.9v-1.6h1.5v1.6H133.6z"/>
      <path d="M134.4,14.2h1.5v1.2h-1.5V14.2z M135.9,21.7h-1.5v-5.4h1.5V21.7z"/>
      <path d="M137.1,17.8c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2s0.8,0.1,1.2,0.2   c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.3,0.6-0.6,0.9   c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1.2,0.2s-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-0.9   c-0.1-0.3-0.2-0.7-0.2-1.2C136.9,18.6,137,18.2,137.1,17.8 M138.4,19.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4   c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4s0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6   s-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4   c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6S138.4,19.4,138.4,19.6"/>
      <path d="M144.8,16.3V17h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1,0.2   c0.3,0.1,0.5,0.3,0.6,0.4s0.2,0.4,0.3,0.7c0.1,0.3,0.1,0.6,0.1,0.9v3.3H147v-3c0-0.4-0.1-0.8-0.2-1c-0.1-0.2-0.4-0.3-0.7-0.3   c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1.2v2.8h-1.5v-5.4H144.8z"/>
      <path d="M151.7,17.8c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.2,0.2   c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.3,0.6-0.6,0.9   c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-0.9   c-0.1-0.3-0.2-0.7-0.2-1.2C151.5,18.5,151.6,18.1,151.7,17.8 M153.1,19.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4   c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6   c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4   c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6S153,19.4,153.1,19.6"/>
      <path d="M157.5,17.3v-1h0.9v-0.4c0-0.5,0.2-0.9,0.4-1.2c0.3-0.3,0.8-0.5,1.4-0.5c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0   v1.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.4,0.1s-0.1,0.3-0.1,0.5v0.3h1v1h-1v4.4h-1.5v-4.4H157.5z"/>
      <path d="M95.7,27.8c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.5-0.3C94.6,27,94.4,27,94.2,27   c-0.4,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,0.2-0.3,0.5-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,1c0,0.3,0,0.6,0.1,0.9   c0.1,0.3,0.2,0.6,0.3,0.8s0.4,0.4,0.6,0.6c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2h1.6   c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.6,0.5-1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.6,0-1.1-0.1-1.5-0.3   c-0.5-0.2-0.8-0.5-1.2-0.8s-0.6-0.8-0.7-1.2c-0.2-0.5-0.3-1-0.3-1.5c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.2   c0.3-0.4,0.7-0.6,1.2-0.8c0.5-0.2,1-0.3,1.5-0.3c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,1,0.5c0.3,0.2,0.5,0.5,0.7,0.8   c0.2,0.3,0.3,0.7,0.4,1.1h-1.6C95.8,28.1,95.8,28,95.7,27.8"/>
      <rect x="98.5" y="25.8" width="1.5" height="7.5"/>
      <path d="M101.1,29.5c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1   c0.3,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8v2.8c0,0.2,0,0.5,0,0.7   c0,0.2,0.1,0.4,0.1,0.5h-1.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.4-0.8,0.5s-0.6,0.1-1,0.1   c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0.1-0.6,0.2-0.8   c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.7-0.1s0.4-0.1,0.7-0.1c0.2,0,0.4-0.1,0.6-0.1   c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1   c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.6H101.1z M104.5,30.6c-0.1,0.1-0.1,0.1-0.2,0.1   c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1   c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3,0   c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.3V30.6z"/>
      <path d="M108.4,31.9c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0   c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.4-0.5c-0.3-0.1-0.7-0.2-1.2-0.3   c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.2-0.9   c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3   c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.3,0.8h-1.4c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0   c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2   c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.4   c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.4-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1   c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.2-0.2-0.5-0.2-0.9h1.4   C108.3,31.7,108.4,31.8,108.4,31.9"/>
      <path d="M114,31.9c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0   c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.4-0.5c-0.3-0.1-0.7-0.2-1.2-0.3   c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.2-0.9   c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3   c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.3,0.8H116c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0   c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2   c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.4   c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.4-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1   c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.6s-0.2-0.5-0.2-0.9h1.4C113.9,31.7,114,31.8,114,31.9"/>
      <path d="M118.4,25.8h1.5V27h-1.5V25.8z M119.9,33.3h-1.5v-5.4h1.5V33.3z"/>
      <path d="M120.5,28.9v-1h0.9v-0.4c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.8-0.5,1.4-0.5c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0   V27c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.5v0.3h1v1h-1v4.4h-1.5v-4.4H120.5z"/>
      <path d="M124.6,25.8h1.5V27h-1.5V25.8z M126.1,33.3h-1.5v-5.4h1.5V33.3z"/>
      <path d="M129.8,28.8c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6s-0.1,0.4-0.1,0.6   c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.6-0.1,0.8-0.3   c0.2-0.2,0.3-0.5,0.4-0.8h1.4c-0.1,0.7-0.4,1.3-0.8,1.6c-0.5,0.4-1,0.6-1.8,0.6c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6   c-0.2-0.2-0.4-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.3,0.5-0.5,0.9-0.6   c0.3-0.1,0.7-0.2,1.2-0.2c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.3,0.9h-1.5   C130.8,29.2,130.5,28.8,129.8,28.8"/>
      <path d="M133.2,29.5c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1   c0.3,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8v2.8c0,0.2,0,0.5,0,0.7   c0,0.2,0.1,0.4,0.1,0.5h-1.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.4-0.8,0.5s-0.6,0.1-1,0.1   c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0.1-0.6,0.2-0.8   c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.7-0.1s0.4-0.1,0.7-0.1c0.2,0,0.4-0.1,0.6-0.1   c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1   c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.6H133.2z M136.6,30.6c-0.1,0.1-0.1,0.1-0.2,0.1   c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1   c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3,0   c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.3V30.6z"/>
      <path d="M142.2,27.9v1h-1.1v2.7c0,0.3,0,0.4,0.1,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0v1.1   c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4   c-0.1-0.2-0.1-0.4-0.1-0.7v-3.2h-0.9v-1h0.9v-1.6h1.5v1.6H142.2z"/>
      <path d="M143,25.8h1.5V27H143V25.8z M144.4,33.3H143v-5.4h1.5V33.3z"/>
      <path d="M145.6,29.4c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.2,0.2   c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.3,0.6-0.6,0.9   c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-0.9   c-0.1-0.3-0.2-0.7-0.2-1.2C145.4,30.1,145.5,29.8,145.6,29.4 M147,31.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4   c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6   c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4   c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6S147,31,147,31.2"/>
      <path d="M153.4,27.9v0.8h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1,0.2   c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.3,0.1,0.6,0.1,0.9v3.3h-1.5v-3c0-0.4-0.1-0.8-0.2-1   c-0.1-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1.2v2.8H152v-5.4H153.4z"/>
      <path d="M92.3,43c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3,0,0.5,0   c0.2,0,0.3-0.1,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.3   c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.4-0.5-0.6   c-0.1-0.2-0.2-0.5-0.2-0.9c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1   c0.4,0,0.8,0,1.1,0.1c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.2,0.7,0.2,1.1h-1.6c0-0.2-0.1-0.4-0.1-0.6   c-0.1-0.1-0.2-0.3-0.3-0.3s-0.3-0.1-0.5-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.1   c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2   c0.3,0.1,0.7,0.2,1.1,0.3c0.1,0,0.3,0.1,0.6,0.2c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.2,0.5,0.4,0.6,0.6c0.2,0.3,0.3,0.6,0.3,1   c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.6,0.8s-0.6,0.4-1,0.5C94.7,45,94.3,45,93.8,45c-0.4,0-0.8,0-1.2-0.2   c-0.4-0.1-0.7-0.3-1-0.5c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.3-0.7-0.3-1.2h1.6C92.2,42.7,92.3,42.9,92.3,43"/>
      <path d="M97.7,41c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.2,0.2   c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.3,0.6-0.6,0.9   c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-0.9   c-0.1-0.3-0.2-0.7-0.2-1.2C97.5,41.8,97.6,41.4,97.7,41 M99.1,42.8c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4   c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6   c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4   c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6S99,42.6,99.1,42.8"/>
      <path d="M106.7,40.4c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6s-0.1,0.4-0.1,0.6   c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.6-0.1,0.8-0.3   c0.2-0.2,0.3-0.5,0.4-0.8h1.4c-0.1,0.7-0.4,1.3-0.8,1.6c-0.5,0.4-1,0.6-1.8,0.6c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6   c-0.2-0.2-0.4-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.3,0.5-0.5,0.9-0.6   c0.3-0.1,0.7-0.2,1.2-0.2c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.3,0.9h-1.5   C107.7,40.8,107.3,40.4,106.7,40.4"/>
      <path d="M110.1,37.4h1.5v1.2h-1.5V37.4z M111.6,44.9h-1.5v-5.4h1.5V44.9z"/>
      <path d="M114.4,43.6c0.2,0.2,0.5,0.3,1,0.3c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3-0.3,0.4-0.5h1.3c-0.2,0.6-0.5,1.1-1,1.4   c-0.4,0.3-1,0.4-1.6,0.4c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.5-0.5-0.9c-0.1-0.3-0.2-0.7-0.2-1.1   c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.2-0.3,0.5-0.5,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.2,0.3   c0.3,0.2,0.6,0.4,0.8,0.7s0.4,0.6,0.5,1c0.1,0.4,0.1,0.8,0.1,1.2H114C114,43,114.1,43.4,114.4,43.6 M116,40.7   c-0.2-0.2-0.4-0.3-0.8-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4h2.4   C116.3,41.2,116.2,40.9,116,40.7"/>
      <path d="M121.7,39.5v1h-1.1v2.7c0,0.3,0,0.4,0.1,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0v1.1   c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4   c-0.1-0.2-0.1-0.4-0.1-0.7v-3.2h-0.9v-1h0.9v-1.6h1.5v1.6H121.7z"/>
      <path d="M122.5,37.4h1.5v1.2h-1.5V37.4z M124,44.9h-1.5v-5.4h1.5V44.9z"/>
      <path d="M126.7,43.6c0.2,0.2,0.5,0.3,1,0.3c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3-0.3,0.4-0.5h1.3c-0.2,0.6-0.5,1.1-1,1.4   c-0.4,0.3-1,0.4-1.6,0.4c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.5-0.5-0.9c-0.1-0.3-0.2-0.7-0.2-1.1   c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.2-0.3,0.5-0.5,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.2,0.3   c0.3,0.2,0.6,0.4,0.8,0.7s0.4,0.6,0.5,1c0.1,0.4,0.1,0.8,0.1,1.2h-3.9C126.4,43,126.5,43.4,126.7,43.6 M128.4,40.7   c-0.2-0.2-0.4-0.3-0.8-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4h2.4   C128.7,41.2,128.6,40.9,128.4,40.7"/>
      <path d="M132.4,43.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0   c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.4-0.5c-0.3-0.1-0.7-0.2-1.2-0.3   c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.2-0.9   c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3   c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.3,0.8h-1.4c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0   c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2   c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.4   c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.4-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1   c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.2-0.2-0.5-0.2-0.9h1.4   C132.3,43.3,132.3,43.4,132.4,43.5"/>
    </g>
    <line class="line" x1="86.5" y1="48.8" x2="86.5" y2="0"/>
  </svg>
</template>

<style scoped>
#IACS_LOGO {fill:#0084C9;}
#IACS_LOGO.inverted {fill:#FFFFFF;}
#IACS_LOGO .line {stroke:#0084C9;stroke-width:0.428;stroke-miterlimit:10;}
#IACS_LOGO.inverted .line {stroke:#FFFFFF;}
</style>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 300
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    height () {
      return this.width / this.aspect
    }
  },
  data () {
    return {
      aspect: 3.2991803279
    }
  }
}
</script>
