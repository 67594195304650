import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

// import '@mdi/font/css/materialdesignicons.css'
// import 'vuetify/dist/vuetify.min.css'
// import minifyTheme from 'minify-css-string'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#0088cb',
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.lightBlue.darken2,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green
      }
    }
  }
}

export default new Vuetify(opts)


  // vuetify: {
  //   customVariables: ['~/assets/variables.scss'],
  //   theme: {
  //     light: true,
  //     themes: {
  //       light: {
  //         primary: '#0088cb',
  //         accent: colors.grey.darken3,
  //         secondary: colors.amber.darken3,
  //         info: colors.lightBlue.darken2,
  //         warning: colors.amber.base,
  //         error: colors.deepOrange.accent4,
  //         success: colors.green
  //       }
  //     }
  //   }
  // },
